<template>
    <div>
      <main-report-table></main-report-table>
    </div>
  </template>
  
  <script>
  import MainReportTable from "@/components/ecologist/reports/MainReport/MainReportTable";
  export default {
    name: "MainReportTableView",
    components: { MainReportTable },
  };
  </script>
  
  <style scoped></style>